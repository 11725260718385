
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Home from "../src/pages/Home";
export default Home;

    async function __Next_Translate__getStaticProps__19276a01c45__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19276a01c45__ as getStaticProps }
  